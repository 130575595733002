import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "effective-problem-solving"
    }}>{`Effective problem-solving`}</h1>
    <h3 {...{
      "id": "2-brainstorm-possible-solutions"
    }}>{`2. Brainstorm possible solutions.`}</h3>
    <p>{`Help your teenager to come up with as many solutions to the problem as possible by `}<strong parentName="p">{`brainstorming`}</strong>{`.`}</p>
    <h3 {...{
      "id": "here-are-some-brainstorming-tips"
    }}>{`Here are some brainstorming tips:`}</h3>
    <ul>
      <li parentName="ul">{`Try to create a free-flowing exchange of ideas between you and your teenager.`}</li>
      <li parentName="ul">{`Think as creatively as possible.`}</li>
      <li parentName="ul">{`Write down all the ideas.`}</li>
      <li parentName="ul">{`Don’t stop to evaluate the ideas – just come up with as many suggestions as you can think of.`}</li>
      <li parentName="ul">{`Try to come up with at least 5 possible solutions.`}</li>
    </ul>
    <h3 {...{
      "id": "for-example-in-response-to-the-problem-i-need-to-improve-my-marks-in-maths-otherwise-i-will-not-pass-the-subject-possible-solutions-could-include"
    }}>{`For example, in response to the problem “I need to improve my marks in Maths otherwise I will not pass the subject”, possible solutions could include:`}</h3>
    <table>
  <thead></thead>
  <tbody>
    <tr>
      <td> Get a Maths tutor. </td>
    </tr>
    <tr>
      <td> Do Maths exercises each night as homework. </td>
    </tr>
    <tr>
      <td> Get extra help from the teacher. </td>
    </tr>
    <tr>
      <td> Have a study partner. </td>
    </tr>
    <tr>
      <td> Ask for study guides. </td>
    </tr>
  </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      